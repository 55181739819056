@media only screen and (max-device-width: 480px) {
	.nav {
		padding: 10px 20px !important;
	}

	.navLinks {
		padding: 25px 20px !important;
	}

	.mainSection {
		padding: 80px 20px 80px 20px !important;
		scroll-snap-align: unset !important;
		height: 100vh !important;
	}

	.section {
		padding: 80px 20px 80px 20px !important;
		scroll-snap-align: unset !important;
		height: fit-content !important;
		border-top: 1px solid var(--background-secondary);
	}

	.heading {
		font-size: 42px !important;
	}

	.button {
		font-size: 13px !important;
	}

	.subheading {
		font-size: 16px !important;
	}

	.skillsGrid {
		margin: 0px !important;
		margin-bottom: auto !important;
		gap: 10px !important;
	}

	.skill {
		height: 80px !important;
		width: 80px !important;
		font-size: 70px !important;
		padding: 8px !important;
	}

	.projectsGrid {
		margin: 0px !important;
		margin-bottom: auto !important;
		gap: 20px;
	}

	.project {
		height: 150px !important;
		width: 300px !important;
		opacity: 1 !important;
		transform: none !important;
		padding: 15px !important;
		align-items: center !important;
	}

	.project > img {
		display: none;
	}

	.project > div {
		margin-top: auto;
	}

	.project > div > a {
		background-color: var(--background-tertiary) !important;
		color: var(--header-secondary) !important;
	}

	.projectHeading {
		font-size: 18px !important;
	}

	.projectDescription {
		font-size: 14px !important;
		margin-bottom: 4px !important;
	}
}
