* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: "Inter", sans-serif;
	transition: background-color 0.1s ease-in, color 0.1s ease-in, border-top 0.1s ease-in;
}

html {
	scroll-snap-type: y mandatory;
	scrollbar-width: thin;
}

/*body::-webkit-scrollbar {
	position: fixed;
	width: 7px;
}

body::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background-color: hsl(0, 0%, 50%);
}
*/
.app {
	background-color: var(--background);
	height: 100%;
	width: 100%;
	min-height: 100vh;
}

.dark {
	--background: hsl(0, 0%, 7%);
	--background-secondary: hsl(0, 0%, 13%);
	--background-tertiary: hsl(0, 0%, 20%);
	--button-active: hsl(0, 0%, 23%);
	--header: hsl(0, 0%, 100%);
	--header-secondary: hsl(0, 0%, 70%);
	--header-tertiary: hsl(0, 0%, 50%);
}

.light {
	--background: hsl(0, 0%, 93%);
	--background-secondary: hsl(0, 0%, 87%);
	--background-tertiary: hsl(0, 0%, 80%);
	--button-active: hsl(0, 0%, 77%);
	--header: hsl(0, 0%, 0%);
	--header-secondary: hsl(0, 0%, 30%);
	--header-tertiary: hsl(0, 0%, 50%);
}
